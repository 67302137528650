/*
nel file index.js nella root del progetto:
    import './assets/fonts/SourceCodePro-Regular.ttf';

nel file .css:
    @font-face {
        font-family: 'SourceCodePro-Regular';
        src:  url('../../assets/fonts/SourceCodePro-Regular.ttf') format('truetype');
    }
    font-family: 'SourceCodePro-Regular';
*/


/* ------- stili generali ------- */

.SimpleTable-wrapper {
    width:              fit-content;
    overflow-y:         scroll;
}

.SimpleTable-wrapper.for-users,
.SimpleTable-wrapper.for-filters {
    max-height:         calc(100vh - 376px);
}

.SimpleTable-wrapper .headerTableTool-outer {
    display:            flex;
    justify-content:    space-between;
    align-items:        center;
    position:           sticky!important;
    right:              0 !important;
    left:               0 !important;
}

.SimpleTable table {
    overflow:           scroll;
    border-spacing:     0;
    table-layout:       fixed;
}

.SimpleTable thead > tr:nth-child(2) > th:nth-child(1) {
    padding-left:       8px;
}

.SimpleTable th,
.SimpleTable td {
    position:           sticky;
    background-color:   white;
}
.SimpleTable thead tr th,
.SimpleTable tr.highlight td {
    z-index:            100;
    top:                0;
}
.SimpleTable tr th.unique {
    z-index:            101;
    left:               0;
}
.SimpleTable tr td.unique {
    z-index:            99;
    left:               0;
}
/*
.SimpleTable thead tr:nth-of-type(2) th {
    top:                33px;
}
.SimpleTable thead tr:nth-of-type(3) th {
    top:                calc( 41px * 2 );
}
.SimpleTable tr.highlight td {
    top:                calc( 41px * 3 );
}
*/
.SimpleTable th,
.SimpleTable td {
    padding:            8px;
    font-size:          0.875rem;
}

.SimpleTable thead tr:last-of-type th {
    border-bottom:      1px solid rgb(224, 224, 224);
}
.SimpleTable td {
    border-bottom:      1px solid rgb(224, 224, 224);
}

.SimpleTable th:not(.check):not(.edit):not(.autocomplete),
.SimpleTable td:not(.check):not(.edit):not(.autocomplete)
{
    white-space:        nowrap;
    overflow:           hidden;
    text-overflow:      ellipsis;
}

.SimpleTable .filter input {
    font-size:              14px;
    padding-left:           5px;
}

.SimpleTable .filter th {
    padding-top:            0 !important;
}

.SimpleTable th {
    text-align:         left;
    color:              rgb(94, 94, 94);
    font-weight:        500;
    font-family:        "Roboto", "Helvetica", "Arial", sans-serif;
}

.SimpleTable td.unique a,
.SimpleTable td.unique {
    /* color:              rgb(74, 74, 74); */
    font-weight:        bold;
}

.SimpleTable td.number {
    text-align:         right;
    font-family:        'CourierPrime', monospace;
    font-size:          16px;
}

/* ------- stili checkbox ------- */

.SimpleTable td.check,
.SimpleTable td.edit   {
    padding:            0;
    cursor:             pointer;
}

.SimpleTable td.check i,
.SimpleTable td.edit  i {
    position:           absolute;
    padding:            0;
    margin:             0;
    top:                50%;
    left:               50%;
    transform:          translate(-50%, -50%);
}

.SimpleTable td.edit:hover i.icon::before {
    filter:             drop-shadow(0px 0px 4px var(--color-shadow));
}

.SimpleTable .check > span {
    padding:            0;
}
/* rimosso temporaneamente
.SimpleTable .check > div {
    min-width:          79px;
}*/


.filter input {
    font-size:              14px;
    padding-left:           5px;
}

.SimpleTable .edit     {    min-width: 47px; width: 47px; max-width: 47px; }
.SimpleTable .check    {    min-width: 47px; width: 47px; max-width: 47px; }
.SimpleTable .label    {    min-width: 170px; width: 170px; }
.SimpleTable .selected {    width:  50px; }
.SimpleTable .sort     {    width: 100px; }
.SimpleTable .drill    {    width: 200px; }
.SimpleTable .exclude  {    width:  75px; }
.SimpleTable .filters  {    width: auto;  }

.SimpleTable .check-sel-all {
    position:               absolute;
    left:                   50%;
    transform:              translate(-50%, -50%);
    top:                    50%;
}

.SimpleTable tr.highlight td {
    background-color:       rgb(189, 219, 191) !important; /* rgb(234, 244, 234) */
}

.SimpleTable .breakline {
    white-space:            normal !important;
}

.SimpleTable .isSame a,
.SimpleTable .isSame {
    color:                  #e0e0e0!important;
}

.SimpleTable div.arrows-icon {
    display:                inline-flex;
    position:               relative;
    width:                  12px;
    height:                 12px;
    margin-right:           2px;
}

 .SimpleTable div.arrows-icon::before
,.SimpleTable div.arrows-icon::after{
    content:                '';
    position:               absolute;
    z-index:                10;
    width:                  0;
    height:                 0;
    border-left:            5px solid transparent;
    border-right:           5px solid transparent;
    border-top:             5px solid transparent;
    border-bottom:          5px solid transparent;
     cursor:                pointer;
}

.SimpleTable div.arrows-icon.updown::before {
    top:                    0;
    left:                   50%;
    transform:              translateX(-50%);
    border-top:             none;
    border-bottom-color:    #b6b6b6;
}
.SimpleTable div.arrows-icon.updown::after {
    left:                   50%;
    transform:              translateX(-50%);
    bottom:                 0;
    border-bottom:          none;
    border-top-color:       #b6b6b6;
}
.SimpleTable div.arrows-icon.updown.ASC::before {
    border-bottom-color:    black;
}
.SimpleTable div.arrows-icon.updown.DESC::after {
    border-top-color:       black;
}


.SimpleTable tr:nth-of-type(odd) td {
    background-color:       #F9F9F9;
}
.SimpleTable tr:nth-of-type(even) td {
    background-color:       #FDFDFD;
}

.SimpleTable tr:hover td {
    background-color:       #FDF9F4 !important;
}

/*
.SimpleTable div.arrows-icon.right::after {
    right:                  0;
    border-right:           none;
    border-left-color:      black;
}

.SimpleTable div.arrows-icon.left::after {
    left:                   0;
    border-left:            none;
    border-right-color:     black;
}
*/

.SimpleTable-wrapper .side-simpletable-menu-opener {
    position: relative;
    border-color:           grey!important;
    width:                  40px!important;
    height:                 40px!important;
    padding:                4px!important;
    min-width:              40px!important;
}

.SimpleTable-wrapper .side-simpletable-menu {
    position:               relative;
    top:                    45px;
    border:                 1px solid #000;
    border-radius:          5px !important;
    z-index:                10000000;
}

.SimpleTable-wrapper .simple-date input,
.SimpleTable-wrapper .simple-number input {
    text-align:             right;
}
.SimpleTable-wrapper .simple-date > div > div {
    margin-left:            0 !important;
}


.SimpleTable-wrapper .select-operator-number {
    border:                 0 !important; 
}

.SimpleTable-wrapper .text-filter-btn {
    font-size:              15px;
    padding:                4px;
    font-weight:            bold;
}
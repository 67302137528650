.anagraphDetail .anagDetail1 .SimpleTable-wrapper {
    max-height: calc( 100vh - 186px );
    height: auto;
}

.anagraphDetail .anagDetail2 .SimpleTable-wrapper {
    max-height: calc( 100vh - 256px ); /* 275 */
    height: auto;
}

.anagraphDetail .component-options-wrapper-anagraphDetail-title {
    border-radius: 5px;
    background-color: #ffffff;
    padding: 10px 0px 10px 0px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0!important;
}

.anagraphDetail .component-options-wrapper-anagraphDetail {
    border-radius: 5px;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0!important;
}

.anagraphDetail .component-options-wrapper-subtitle {
    padding-right: 15px;
}
.anagraphDetail .component-options-wrapper-subtitle > a > button > span:nth-of-type(2) {
    line-height: 1.2;
}

.anagraphDetail .titleAnagraficaDettaglio.LINE_ITEMS {
    max-width: calc( 100vw - 979px);
    max-height: 42px;
    white-space: nowrap!important;
    overflow: hidden!important; 
    text-overflow: ellipsis!important;
}

.anagraphDetail .titleAnagraficaDettaglio.AD_UNITS {
    max-width: 754px;
    max-height: 42px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* @media screen and (max-width: 1550px) {
    .anagraphDetail .titleAnagraficaDettaglio.LINE_ITEMS {
        max-width: 600px!important;
    }
    
    .anagraphDetail .titleAnagraficaDettaglio.AD_UNITS {
        max-width: 380px!important;
    }
} */


.anagraphDetail .LINE_ITEMS .flexyTitle {
    max-width: calc( 100vw - 979px);
    white-space: nowrap!important;
    overflow: hidden!important;
    text-overflow: ellipsis!important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.anagraphDetail .PLACEMENTS .flexyTitle,
.anagraphDetail .AD_UNITS   .flexyTitle {
    max-width: calc( 100vw - 1212px) ;
    min-width: 160px;
    white-space: nowrap!important;
    overflow: hidden!important;
    text-overflow: ellipsis!important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.anagraphDetail .LINE_ITEMS .dettaglioDescription {
    max-width: calc( 100vw - 972px);
    white-space: nowrap!important;
    overflow: hidden!important;
    text-overflow: ellipsis!important;
    font-size: 0.75rem;
}

.anagraphDetail .PLACEMENTS .dettaglioDescription,
.anagraphDetail .AD_UNITS   .dettaglioDescription {
    max-width: calc( 100vw - 1212px) ;
    min-width: 165px;
    white-space: nowrap!important;
    overflow: hidden!important;
    text-overflow: ellipsis!important;
    font-size: 0.75rem;
}

/* @media screen and (max-width: 1536px) {
    .anagraphDetail .AD_UNITS .flexyTitle {
        max-width: calc( 100vw - 261px)!important;
    }

    .anagraphDetail .AD_UNITS .dettaglioDescription {
        max-width: calc( 100vw - 261px)!important;
    }
} */

.anagraphDetail .reloadButton {
    margin-left: 18px;
    /* color: #000;
    border: 1px solid #000;
    border-radius: 5px !important;
    text-decoration: none !important; */
}

/*.anagraphDetail .backButton .myIcon {
    font-size: 1.5rem;
} */

.anagraphDetail {
    min-width: 149px;
}

/* .localsole .anagraphDetail .reloadButton:hover,
.prodsole  .anagraphDetail .reloadButton:hover,
.localsole .anagraphDetail .backButton:hover,
.prodsole  .anagraphDetail .backButton:hover {
    background-color:   var(--color-button)!important;
    border-color:       var(--color-dark)!important;
}

.local    .anagraphDetail .backButton:hover,
.proddisc .anagraphDetail .backButton:hover,
.local    .anagraphDetail .reloadButton:hover,
.proddisc .anagraphDetail .reloadButton:hover {
    background-color:   var(--color-button)!important;
    border-color:       var(--color-dark)!important;
    color:              var(--color-dark)!important;
} */


.anagrafiche    .link,
.anagraphDetail .link {
    text-decoration: none;
    color: #000000 !important;
}

.anagrafiche    .link i.icon::before {
    padding-top: 1px;
}

.AnagrafichePopUp .PopUpContent {
    padding: 0px 15px;
    max-width: 700px;
}

.AnagrafichePopUp .input-field.flag-admin {
    justify-content: normal;
}


.AnagrafichePopUp .AutocompletePopup {
    width: 700px;
    min-width: 700px;
    margin-bottom: 18px;
    max-width: 700px
}

.AnagrafichePopUp .mySelect {
    width:      700px;
    min-width:  700px;
}

.AnagrafichePopUp .mySelect.mybordercolor.alerts {
    /* width:      150px !important;
    min-width:  150px !important; */
    min-height: 58px  !important;
}

.alert-flex {
    display:        flex;
    flex-direction: row;
}

.AnagrafichePopUp .selPlacementDateGroup {
    display:         flex;
    justify-content: center;
    flex-wrap:       nowrap;
    gap:             15px;
    margin: 11px 0px!important;

}

.AnagrafichePopUp .ValGapper {
    display:         flex;
    gap:             6px;
    margin:             0;

}

/* .AnagrafichePopUp .adjustPopUp {
    margin-top: 13px;
    margin-bottom: 13px;
} */

.FiltersButton {
    display: flex;
    align-items: center;
    padding: 8px 10px;
    gap: 2px;
    position: relative;
    background-color: white;
    cursor: pointer;
}


.AnagrafichePopUp .mybordercolor fieldset{
   border-color: var(--color-dark)!important;
}

.AnagrafichePopUp .select {
    width: 100%;

}
.AnagrafichePopUp.title {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 600;
    height: 20px;
    padding-bottom: 10px;
}


.AnagrafichePopUp .selectVal {
    width: 77px;
    text-align: center;
    margin: 8px 0px !important;
}

.AnagrafichePopUp .selectProgOb {
    width: 617.5px;
    margin: 8px 0px!important;
}

.AnagrafichePopUp .dateFiltro {
    width: 343px;
}

.SelectStatusGamLabel {
    font-size: 0.97rem!important;
    margin-left: 9px;
}


/* .anagraphDetail th.NUM_PLACEMENTS[title='Placements'] {
    text-overflow: unset;
} */

.anagraphDetail td.INDICATOR,
.anagraphDetail td.CONTENDING_COUNT,
.anagraphDetail td.NUM_AD_UNITS,
.anagraphDetail td.NUM_PLACEMENTS,
.anagraphDetail td.NUM_LINE_ITEMS,
.anagraphDetail td.AD_UNIT_ID,
.anagraphDetail td.PLACEMENT_ID,
.anagraphDetail td.LINE_ITEM_ID {
    padding: 0 !important;
}

.anagraphDetail td.AD_UNIT_ID.unique,
.anagraphDetail td.PLACEMENT_ID.unique,
.anagraphDetail td.LINE_ITEM_ID.unique {
    padding: 0 4px 0 3px!important;
}

.anagraphDetail td.CONTENDING_COUNT   .link,
.anagraphDetail td.NUM_AD_UNITS       .link,
.anagraphDetail td.NUM_PLACEMENTS     .link,
.anagraphDetail td.NUM_LINE_ITEMS     .link,
.anagrafiche    td.SUBCATEGORIA       .link,
.anagraphDetail td.AD_UNIT_ID         .link,
.anagraphDetail td.PLACEMENT_ID       .link,
.anagraphDetail td.LINE_ITEM_ID       .link {
    display:         flex;
    text-align:      center !important;
    justify-content: center;
}
.anagrafiche    th.NUM_ELEM,
.anagraphDetail th.AD_UNIT_ID,
.anagraphDetail th.PLACEMENT_ID,
.anagraphDetail th.LINE_ITEM_ID,
.anagraphDetail th.BEGIN_DATE,
.anagraphDetail th.END_DATE,
.anagraphDetail th.DATA_ORA,
.anagraphDetail th.DATA,
.anagraphDetail th.ORA,
.anagraphDetail th.STATUS,
.anagraphDetail th.LINE_ITEM_TYPE,
.anagraphDetail th.PESO,
.anagraphDetail th.PRIORITY,
.anagraphDetail th.DELIVERY_ESTIMATED_PERC,
.anagraphDetail th.ACTUAL_PROG_PERC,
.anagraphDetail th.OBJ_PROG_PERC,
.anagraphDetail th.PACING,
.anagraphDetail th.BACINO,
.anagraphDetail th.OCCUPATO,
.anagraphDetail th.NUM_AD_UNITS,
.anagraphDetail th.NUM_PLACEMENTS,
.anagraphDetail th.NUM_LINE_ITEMS,
.anagraphDetail th.LIVELLO,
.anagraphDetail th.FOGLIA ,
.anagraphDetail th.IMPRESSIONS_DELIV,
.anagraphDetail th.IMPRESSIONS_SCHEDULED {
    text-align: center;
    padding: 2px!important;
}

.anagraphDetail td.LIVELLO,
.anagraphDetail td.FOGLIA,
.anagraphDetail td.STATUS,
.anagraphDetail td.PESO,
.anagraphDetail td.PRIORITY,
.anagraphDetail td.LINE_ITEM_TYPE {
    text-align: center;
}

.anagraphDetail td.BEGIN_DATE,
.anagraphDetail td.END_DATE,
.anagraphDetail td.DATA_ORA,
.anagraphDetail td.DATA,
.anagraphDetail td.ORA {
    text-align: right;
}

.anagraphDetail td.AD_UNIT_SIZES {
    text-align: left;
}

.anagraphDetail tr:nth-of-type(2) th.INDICATOR:not(.unique) div {
    visibility: hidden;
}

.AnagrafichePopUp .input-field.flag-admin {
    padding: 8px;
    border: 0.001rem solid var(--color-dark)!important ;
    margin-bottom: 18px;
    border-radius: 4px;
}

.AnagrafichePopUp .Mui-error {
    color: rgba(0, 0, 0, 0.6) !important;
}

.AnagrafichePopUp .PriorityList {
    width:          100%;
    margin-bottom:  8px!important;
}
.anagraphDetail .grafico {
    max-height: calc(100vh - 226px);
    max-width:      100%;
    height:     calc(100vh - 226px);
}

.anagraphDetail .tabPanel {
    padding:        0px!important;
}

.AdUnit_Flag {
    color:        rgb(130, 126, 126);
    margin-left:    3px;
}

.anagraficaPopupWrapper .MuiDialog-container .MuiPaper-root {
    max-width:      1000px;
}

.anagraphDetail .storicoDPicker {
    display:        flex;
    margin-right:   1.8rem;
    gap:            0.6rem;
    align-items:    center;
    color:        black;
}

.anagraphDetail .singularPicker {
    margin-left:    1.3rem;
}

.storicoDPickerComponent .MuiInputBase-root  {
    height:         43px;
    width:          167px;
}

.anagraphDetail .d-flex .icon::before {
    color:      var(--color-dark);
    font-weight:    bold;
}

.anagraphDetail .d-flex .icon:hover::before {
    color: black;
    cursor: pointer;
}

/* .anagraphDetail .sticky1 {
    position:           -webkit-sticky;
    position:           sticky;
    background-color:   white;
    z-index:            100;
    right:              0px;
}

.anagraphDetail .sticky2 {
    position:           -webkit-sticky;
    position:           sticky;
    background-color:   white;
    z-index:            100;
    right:              49px;
}

.anagraphDetail th.sticky1 {
    z-index:            101;
}

.anagraphDetail th.sticky2 {
    z-index:            101;
} */

/* .anagraphDetail .myShadow:hover { 
    background-color: #ffffff!important;
} */


.anagrafiche .accSelect {
    width: 180px;
    padding: 0px;
    margin-left: 11px;
    margin-bottom: 5px;
}

.anagrafiche label.smallMarginLeft {
    margin-left: 11px;
}

.anagraphDetail .contendingCount {
    font-size: 12px;
    position: absolute;
    right: 41px;
    top: 13px;
    font-weight: bold;
}

.AnagrafichePopUp .CheckboxStile {
    width: 680px!important;
}

/* .anagraphDetail .link:hover .contendingCount {
    color: var( --color-dark );
} */
td.ALERTS {
    padding: 0px    !important;
    text-align:      center;
}
.anagraphDetail .ALERTS.A01,
.anagraphDetail .ALERTS.A03,
.anagraphDetail .ALERTS.A05,
.anagraphDetail .ALERTS.A10 {
    box-shadow: inset 0 0px 0px 43px rgb(202 202 202 / 19%);
}

.anagraphDetail .colgroup {
    border-left:  1px solid #e0e0e0;
    font-size:                  16px;
    font-weight:                600;
    letter-spacing:             1px;
    text-align:                 center;
    border-top-left-radius:     15px;
    border-top-right-radius:    15px;
}

.anagraphDetail .ingroup.Allarmi.firstCol {
    border-left:  1px solid #e0e0e0;
}
.anagraphDetail .ingroup.Allarmi.lastCol {
    border-right:  1px solid #e0e0e0;
}

.anagraphDetail .colgroup.Allarmi {
    box-shadow:                 inset 0 0 0 43px rgb(202 202 202 / 19%);
    border-top:   1px solid #e0e0e0;
    border-left:  1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0; 
}

.anagraphDetail .ingroup.Impressions.firstCol {
    border-left:  1px solid #ddc4a9;
}

.anagraphDetail .ingroup.Impressions.lastCol {
    border-right:  1px solid #ddc4a9;
}

.anagraphDetail .colgroup.Impressions {
    box-shadow:                 inset 0 0 0 43px rgb(230 213 196 / 19%);
    border-top:   1px solid #ddc4a9;
    border-left:  1px solid #ddc4a9;
    border-right: 1px solid #ddc4a9; 
}

/* .anagraphDetail .colgroup {
    border-right: 1px solid rgb(224, 224, 224);
} */

.anagraphDetail .component-options-wrapper-anagraphDetail .headerTableTool-inner {
    width: 100%;
    display: flex;
    justify-content: space-between;
}